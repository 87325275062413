@font-face {
  font-family: 'AdventPro';
  src: url('./fonts/AdventPro-Regular.ttf') format('truetype');
}

body {
  font-family: 'AdventPro', 'Noto Sans KR', sans-serif;
  background-color: #FFFFFF;
  margin: 0;
  padding: 0;
  color: #000000;
}

.header-text {
  color: #CD2E3A; /* Red from Korean flag */
  text-align: center;
  margin-top: 20px;
  font-family: 'AdventPro', sans-serif; /* Explicitly apply AdventPro */
}

.subtitle-text {
  color: #0047A0; /* Blue from Korean flag */
  text-align: center;
  margin-bottom: 20px;
  font-family: 'AdventPro', sans-serif; /* Explicitly apply AdventPro */
}
